<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>Returns</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
              <v-btn small fab color="info" @click="openCreateReturnDialog" class="ml-2"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!loader">
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
                <template v-slot:item.customerName="{ item }">
                  <span>{{ item.Customer?item.Customer.name:"NONE" }}</span>
                </template>
                <template v-slot:item.CSRName="{ item }">
                  <span>{{ item.createdBy?lookupUsername(item.createdBy):"None" }}</span>
                </template>
                <template v-slot:item.status="{ item }">

                  <span v-if="item">
                    <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===100" class="v-btn red pa-1" style="font-size: 10px">Draft (Unsaved)</span>
                    <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===3" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===4" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===5" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===6" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{ utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn class="mr-1" v-if="isAllowed('Return', 'r')" fab x-small color="info" @click="rowClick(item)">
                    <v-icon>mdi-file</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createReturnDialog.isOpen"
      width="600"
      fixed
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Confirm Create Return</b>
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="closeCreateReturnDialog">Cancel</v-btn>
          <v-btn color="success" text @click="attemptCreateReturn()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  import utils from  '../../plugins/helpers'
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Date Created', value: 'createdAt' },
          { text: 'CSR', value: 'CSRName' },
          { text: 'Customer', value: 'customerName' },
          { text: 'Status', value: 'status' },
          // { text: 'Actions', value: 'actions' },
        ],
        itemHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Name', value: 'name' },
          { text: 'QTY', value: 'quantity' },
          { text: 'Unit Price', value: 'unitPrice' },
          { text: 'Line Total', value: 'lineTotal' },
        ],
        editMode: false,

        selectedReturn: null,

        data: {},
        allData: [],
        singular: "return",
        singularLower: "return",
        plural: "returns",
        pluralLower: "returns",

        createReturnDialog: {
          isOpen: false,
          customerId: ''
        },
        rowClicks: 1,
        lastRowClickId: null,
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getUsername', 'getEndpoint', 'isAllowed', 'getPerms', 'getId', 'lookupUsername'])
    },
    methods: {
      ...mapMutations(["setSnack"]),
      async rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      async closeRow(){
        await this.$router.replace({path: `/${this.pluralLower}`, query: null});
        this.selectedReturn = null;
      },
      openReturn(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      openCustomer(row){
        this.$router.push({ path: `/customers/view/${row}`})
      },
      openEmployee(row){
        this.$router.push({ path: `/users/view/${row}`})
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableData`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data
          if(this.$route.query.return){
            let id = parseInt(this.$route.query.return);
            this.selectedReturn = this.allData.find(x => x.id===id);
            if(!this.selectedReturn) this.closeRow()
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false;
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      openCreateReturnDialog(){
        this.createReturnDialog.isOpen = true
      },
      closeCreateReturnDialog(){
        this.createReturnDialog.isOpen = false
      },
      async attemptCreateReturn(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/returns`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/returns/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      },
      parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      goToReturn(){
        console.log("Hi")
      }
    }
  }
</script>
